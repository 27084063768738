import React, { InputHTMLAttributes } from "react"

const InputWithFormat: React.FC<InputHTMLAttributes<HTMLInputElement> & { format: RegExp }> = ({ format, ...rest }) => {
  const [value, setValue] = React.useState<InputHTMLAttributes<HTMLInputElement>["value"]>(rest.value || "")

  const formatValue = (val: string) => {
    const formatted = val.match(format)?.join("")
    setValue(formatted)
  }

  return (
    <input
      type="text"
      {...rest}
      value={value}
      onChange={event => {
        formatValue(event.target.value)
        rest.onChange && rest.onChange(event)
      }}
    />
  )
}

export default InputWithFormat
