import React, { useContext } from "react"
import { PageContext } from "../../context/PageContext"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import { PimUnitSystem } from "../../types"
import Checkbox from "../atoms/form/Checkbox"
import InputWithFormat from "../atoms/form/InputWithFormat"
import * as styles from "./SaunaCalculator.module.scss"

type SaunaCalculatorProps = {
  onResult: (result: number) => void
  units?: PimUnitSystem
}

const SaunaCalculator = (props: SaunaCalculatorProps) => {
  //console.log('Rendering SaunaCalculator', props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("saunaCalculator", locale)

  const unit = props.units === "imperial" ? "ft" : "m"
  const imperialMultiplier = 10.76 // Muuntaa neliömetrit neliöjaloiksi
  const inputFormat = /[.,\d]*/g

  const [state, setState] = React.useState({ in1: 0, in2: 0, in3: 0, mat_log: 0, mat_glass: 0, mat_cold: 0 })

  function update(key: string, val: string) {
    const valAsNumber = parseFloat(val.replace(",", ".")) || 0
    setState({ ...state, [key]: valAsNumber })
  }

  React.useEffect(() => {
    // Tilavuus
    let res = Math.max(state.in1, 0) * Math.max(state.in2, 0) * Math.max(state.in3, 0)

    // Sauna on hirttä
    res *= state.mat_log ? 1.5 : 1

    // Eristämätöntä seinää
    res += Math.max(state.mat_cold, 0) * 1.2

    // lasiovi
    res += state.mat_glass * 1.5 * (unit === "ft" ? imperialMultiplier : 1)

    props.onResult(Math.ceil(res))
  })

  return (
    <div className={styles.container}>
      <div className={styles.fieldset}>
        <h3>{t("roomSizeHeading")}</h3>
        <label className={styles.field}>
          <InputWithFormat
            inputMode="decimal"
            format={inputFormat}
            onChange={e => update("in1", e.currentTarget.value)}
            className={styles.input}
          />
          {t("depth")} ({unit})
        </label>
        <label className={styles.field}>
          <InputWithFormat
            inputMode="decimal"
            format={inputFormat}
            onChange={e => update("in2", e.currentTarget.value)}
            className={styles.input}
          />
          {t("width")} ({unit})
        </label>
        <label className={styles.field}>
          <InputWithFormat
            inputMode="decimal"
            format={inputFormat}
            onChange={e => update("in3", e.currentTarget.value)}
            className={styles.input}
          />
          {t("height")} ({unit})
        </label>
      </div>
      <div className={styles.fieldset}>
        <h3>{t("constructionHeading")}</h3>
        <Checkbox
          label={t("madeOfLog")}
          inputProps={{ onChange: e => update("mat_log", e.currentTarget.checked ? "1" : "0") }}
        />
        <Checkbox
          label={t("glassDoor")}
          inputProps={{ onChange: e => update("mat_glass", e.currentTarget.checked ? "1" : "0") }}
        />
        <label className={styles.field}>
          <p>{t("uninsulatedArea")}</p>
          <InputWithFormat
            inputMode="decimal"
            format={inputFormat}
            className={styles.input}
            onChange={e => update("mat_cold", e.currentTarget.value)}
          />
          {unit}²
        </label>
      </div>
    </div>
  )
}

export default SaunaCalculator
